import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import scroll from 'scroll';
import dom from 'dom-helpers';

class ScrollToTop extends Component {
	componentDidUpdate(prevProps) {
		if (this.props.location !== prevProps.location) {
			const homeToProjects = this.props.location.pathname == '/proyectos' && prevProps.location.pathname == '/';
			const top = dom.scrollTop(dom.ownerDocument());
			const duration = Math.sqrt(top) + 200;
			scroll.top(dom.ownerDocument().documentElement, 11, { duration });
		}
	}

	render() {
		return this.props.children || null;
	}
}
export default withRouter(ScrollToTop);
