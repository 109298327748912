import React, { Component, Fragment } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import dom from 'dom-helpers';

import { Provider, actions, connect } from '../store/index.js';

import '../common/base.css';
import css from './app.css';

import Background from '../components/Background';

import ScrollToTop from '../components/ScrollTop';
import Track from '../components/Track';
import Header from '../components/Header';
import Footer from '../components/Footer';

import Routes from './routes';

class App extends Component {
	constructor(props) {
		super(props);
		this.handleOnScroll = this.handleOnScroll.bind(this);
		this.handleOnResize = this.handleOnResize.bind(this);
	}
	componentDidMount() {
		this.timer = setTimeout(() => actions.set({ loaded: true }), 2000);
		window.addEventListener('scroll', this.handleOnScroll);
		window.addEventListener('resize', this.handleOnResize);
		window.addEventListener(
			'touchstart',
			function onFirstTouch() {
				actions.set({ isTouch: true });
				window.removeEventListener('touchstart', onFirstTouch, false);
			},
			false
		);
	}
	componentWillUnmount() {
		clearTimeout(this.timer);
		window.removeEventListener('scroll', this.handleOnScroll);
		window.removeEventListener('resize', this.handleOnResize);
	}
	handleOnScroll() {
		const win = dom.ownerWindow();
		actions.setScrollTop(dom.scrollTop(win));
	}
	handleOnResize() {
		const win = dom.ownerWindow();
		const dim = { windowWidth: dom.width(win), windowHeight: dom.height(win) };
		actions.set(dim);
	}

	render() {
		return (
			<Provider>
				<Background />
				<Router>
					<Fragment>
						<Track />
						<ScrollToTop />
						<Header {...this.state} />
						<main className={css.main}>
							<Routes />
						</main>
						<Footer />
					</Fragment>
				</Router>
			</Provider>
		);
	}
}

export default App;
