import { initStore } from 'react-waterfall';

import dom from 'dom-helpers';

import data, { loggedIn } from '../common/data';

import { searchProjects, filterProjects, sortProjectListBy, findProjectBySlug } from '../common/helpers';

const win = dom.ownerWindow();

const store = {
	initialState: Object.assign(
		{
			initialLoad: true,
			isTouch: false,
			windowWidth: dom.width(win),
			windowHeight: dom.height(win),
			scrollTop: dom.scrollTop(win),
			scrollDistance: 0,
			headerHover: false,
			headerActive: dom.scrollTop(win) < 10,

			loaded: false,
			projectGrid: data.proyectos.proyectos.slice(),
			currentCategory: '',
			projectList: sortProjectListBy(data.proyectos.proyectos.slice(), 'ano', true),
			searchTerm: '',
			aboutHeight: 0,
			loggedIn: loggedIn,
		},
		data
	),
	actions: {
		set: (_, newState) => {
			return newState;
		},
		setScrollTop: ({ scrollTop, scrollDistance, headerHover }, newScrollTop) => {
			const scrollDelta = newScrollTop - scrollTop;
			const atPageTop = newScrollTop < 10;
			if (scrollDelta > 0) {
				scrollDistance = scrollDistance >= 0 ? scrollDistance + scrollDelta : 0;
			} else if (scrollDelta < 0) {
				scrollDistance = scrollDistance <= 0 ? scrollDistance + scrollDelta : 0;
			}
			return {
				scrollTop: newScrollTop,
				scrollDistance,
				atPageTop,
				headerActive: headerHover ? true : atPageTop || scrollDistance < -200,
			};
		},
		toggleHeaderHover({ atPageTop, scrollDistance }, headerHover) {
			return { headerActive: headerHover || atPageTop, headerHover };
		},
		filterProjectGrid(_, newCategory) {
			return {
				projectGrid: filterProjects(newCategory),
				currentCategory: newCategory,
			};
		},
		searchProjectList({ searchTerm }, newTerm) {
			return {
				projectList: searchProjects(newTerm),
				searchTerm: newTerm,
			};
		},
		sortProjectListBy({ projectList }, sortBy, asc) {
			return {
				projectList: sortProjectListBy(projectList, sortBy, asc),
				sortBy: sortBy,
				sortByAsc: asc,
			};
		},
		findProjectBySlug(_, slug) {
			return {
				currentProject: findProjectBySlug(slug),
			};
		},
	},
};

export const { Provider, connect, actions } = initStore(store);
