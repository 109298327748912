import React, { PureComponent, Fragment } from 'react';
import classnames from 'classnames';
import { Route, Redirect } from 'react-router-dom';
import { connect } from '../../store';

import dom from 'dom-helpers';

import ProjectGrid from '../../components/ProjectGrid';
import ProjectList from '../../components/ProjectList';

import { slugify, findProjectBySlug, findCategoryBySlug, searchProjects, filterProjects } from '../../common/helpers';
import { proyectos } from '../../common/data';

import css from './projects.css';
import grid from '../../common/grid.css';

class Projects extends PureComponent {
	constructor(props) {
		super(props);
		this.updateProjects = this.updateProjects.bind(this);
	}
	componentDidMount() {
		window.addEventListener('resize', this.setAboutHeight);
		this.updateProjects(this.props.location);
		this.unlisten = this.props.history.listen(this.updateProjects);
	}
	componentDidUpdate(prevProps) {
		this.setAboutHeight();
	}
	componentWillUnmount() {
		this.unlisten();
		window.removeEventListener('resize', this.setAboutHeight);
		this.props.actions.set({
			aboutHeight: 0,
		});
	}
	updateProjects({ pathname }) {
		const { actions, history } = this.props;
		const parts = pathname.split('/');
		if (pathname == '/' || pathname == '/proyectos') {
			actions.filterProjectGrid();
		} else if (parts[2] == 'buscar') {
			actions.searchProjectList(parts[3]);
		} else if (parts[1] == 'proyectos') {
			if (findCategoryBySlug(parts[2])) {
				actions.filterProjectGrid(parts[2]);
			} else if (findProjectBySlug(parts[2])) {
				history.push(`/proyecto/${parts[2]}`);
			} else if (/(proyect|project)/.test(parts[1])) {
				history.push('/proyectos');
			}
		}
	}

	ref = React.createRef();
	setAboutHeight = () => {
		if (!this.ref.current) return;
		this.props.actions.set({
			aboutHeight: dom.height(this.ref.current),
		});
	};

	render() {
		const { scrollTop, aboutHeight } = this.props;
		const isHome = this.props.location.pathname == '/';
		const list = this.props.location.pathname.indexOf('/buscar') > -1;

		return (
			<Fragment>
				{isHome && (
					<section ref={this.ref} className={classnames(grid.flexboxgrid, css.about)}>
						<div className={classnames(grid.cell, grid.five)}>
							<h1 className={css.logo}>{this.props.home.title}</h1>
							<div dangerouslySetInnerHTML={{ __html: this.props.home.text }} />
						</div>
					</section>
				)}

				{list ? <ProjectList /> : <ProjectGrid />}
			</Fragment>
		);
	}
}

export default connect(({ home, scrollTop, aboutHeight }) => ({ home, scrollTop, aboutHeight }))(Projects);
