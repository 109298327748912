import React from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';

import Projects from '../pages/Projects';
import Tag from '../pages/Tag';
import Project from '../pages/Project';
import Oficina from '../pages/Oficina';
import Profile from '../pages/Profile';

export default props => (
	<Switch>
		<Route exact path="/" component={Projects} />
		<Route exact path="/proyectos/buscar/:term?" component={Projects} />
		<Route exact path="/proyectos/:category?" component={Projects} />
		<Route exact path="/proyecto/:slug" component={Project} />
		<Route exact path="/tag/:tag" component={Tag} />
		<Route exact path="/oficina" component={Oficina} />
		<Route exact path="/oficina/:persona" component={Profile} />
		<Route exact path="/tipo:oficinas" render={() => <Redirect to="/proyectos/oficina" />} />
		<Route exact path="/tipo:vivienda" render={() => <Redirect to="/proyectos/vivienda" />} />
		<Route path="/" render={() => <Redirect to="/" />} />
	</Switch>
);
