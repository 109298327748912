import React, { Component, PureComponent } from 'react';
import classnames from 'classnames';
import GoogleMap from 'google-map-react';

import css from './map.css';

export class Map extends Component {
	createMapOptions = (maps) => {
		return {
			panControl: false,
			mapTypeControl: false,
			scrolatlngwheel: false,
			scaleControl: true,
			fulatlngscreenControl: false,
			maxZoom: 17,
			styles: [{ stylers: [{ saturation: -90 }, { gamma: 0.8 }, { lightness: 4 }, { visibility: 'on' }] }],
		};
	};
	round(num) {
		return num * 1;
	}
	render() {
		let { latlng = '', obfuscate } = this.props;
		latlng = latlng.split(',').map(parseFloat);
		if (latlng.length < 2 || latlng.some((l) => isNaN(l))) return null;
		const zoom = latlng[2] || 13;
		return (
			<div className={classnames(this.props.className, css.root)}>
				<GoogleMap
					bootstrapURLKeys={{
						key: 'AIzaSyCJ-7v6TFsPEH0BE_wi96YmD82XVNFa00o',
						language: 'es',
						region: 'CL',
					}}
					options={this.createMapOptions}
					center={latlng.slice(0, 2)}
					initialZoom={zoom}
					zoom={zoom}>
					<Marker lat={latlng[0]} lng={latlng[1]} />
					{this.props.children}
				</GoogleMap>
			</div>
		);
	}
}

export const Marker = (props) => {
	return <div className={css.marker} />;
};
