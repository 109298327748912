import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import classnames from 'classnames';

import css from './image.css';

class Image extends Component {
	constructor(props) {
		super(props);
		this.img = React.createRef();
		this.state = { loaded: false };
		this.imageLoaded = this.imageLoaded.bind(this);
	}
	imageLoaded() {
		this.setState({ loaded: true });
	}
	componentDidMount() {
		const node = this.img.current;
		if (!node) return;
		node.addEventListener('load', this.imageLoaded);
	}
	componentWillUnmount() {
		const node = this.img.current;
		if (!node) return;
		node.removeEventListener('load', this.imageLoaded);
	}
	render() {
		const { sizes, alt, className, caption, tags, onClick, ratio } = this.props;

		const image = (
			<div
				data-ratio={ratio}
				style={{
					paddingTop: `${100 / ratio}%`
				}}
				className={classnames(css.image, className, {
					[css.square]: ratio <= 1.05 && ratio >= 0.95
				})}>
				<img
					ref={this.img}
					src={sizes.small}
					srcSet={`${sizes.medium} 700w, ${sizes.large} 1200w`}
					alt={alt}
					onClick={e => onClick && onClick(e)}
				/>
				<div
					className={classnames(css.placeholder, {
						[css.deactivated]: this.state.loaded
					})}
				/>
			</div>
		);
		return caption ? (
			<figure className={css.figure}>
				{image}
				<figcaption className={css.caption}>{caption}</figcaption>
			</figure>
		) : (
			image
		);
	}
}

Image.propTypes = {
	src: PropTypes.string.isRequired,
	placeholder: PropTypes.string,
	imgClasses: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	holderClasses: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	onClick: PropTypes.func,
	alt: PropTypes.string
};

export default Image;
