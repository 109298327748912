import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import classnames from 'classnames';

import { connect } from '../../store';

import Oficina from '../../common/svg/oficina.svg';

import grid from '../../common/grid.css';
import css from './footer.css';

const Footer = (props) => {
	return (
		<footer className={css.footer}>
			<div className={grid.flexboxgrid}>
				<div className={classnames(grid.cell, grid.five)}>
					<Link
						className={css.logo}
						style={{ height: 20, color: 'inherit', border: 'none', fill: 'currentColor' }}
						to="/oficina">
						{props.title}
					</Link>
					<div dangerouslySetInnerHTML={{ __html: props.contacto }} />
				</div>
			</div>
		</footer>
	);
};

export default withRouter(connect(({ oficina, home }) => ({ title: home.title, contacto: oficina.contacto }))(Footer));
