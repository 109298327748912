import React, { Component, Fragment } from 'react';
import classnames from 'classnames';
import Figure from '../../components/Figure';

import { connect } from '../../store';

import css from './oficina.css';
import grid from '../../common/grid.css';

class Oficina extends Component {
	ref = React.createRef();
	componentDidMount() {
		this.ref.current.querySelectorAll('a').forEach((a) => a.addEventListener('click', this.handleLocalLinks));
	}
	copmponentWillUnmount() {
		this.ref.current.querySelectorAll('a').forEach((a) => a.removeEventListener('click', this.handleLocalLinks));
	}
	handleLocalLinks = (e) => {
		const isLocal = e.target.href.indexOf(window.location.origin) == 0 || e.target.href[0] == '/';
		e.preventDefault();
		if (isLocal) {
			const url = e.target.href.replace(window.location.origin, '');
			this.props.history.push(url);
		} else {
			window.open(e.target.href, '_blank');
		}
	};

	render() {
		const { images, about, servicios, contacto } = this.props;

		const secciones = [
			<section id="oficina" className={classnames(grid.cell, grid.two, grid.smallFive)}>
				<h1>Oficina</h1>
				<div dangerouslySetInnerHTML={{ __html: this.props.about }} />
			</section>,

			<section id="servicios" className={classnames(grid.cell, grid.two, grid.smallFive)}>
				<h1>Servicios</h1>
				{<div dangerouslySetInnerHTML={{ __html: this.props.servicios }} />}
			</section>,

			<section id="contacto" className={classnames(grid.cell, grid.one, grid.smallFive)}>
				<h1>Contacto</h1>
				<div dangerouslySetInnerHTML={{ __html: this.props.contacto }} />
			</section>,
			<section id="equipo" className={classnames(grid.cell, grid.two, grid.smallFive)}>
				<h1>Equipo</h1>
				<div dangerouslySetInnerHTML={{ __html: this.props.equipo }} />
			</section>,
			<section id="han-colaborado" className={classnames(grid.cell, grid.three, grid.smallFive)}>
				<h1>Han Colaborado</h1>
				<div dangerouslySetInnerHTML={{ __html: this.props.han_colaborado }} className={css.threecol} />
			</section>,
			<section
				id="publicaciones"
				className={classnames(grid.cell, grid.three, grid.smallFive, css.publicaciones)}>
				<h1>Publicaciones</h1>
				<div dangerouslySetInnerHTML={{ __html: this.props.publicaciones }} />
			</section>,
			<section id="practicas" className={classnames(grid.cell, grid.two, grid.smallFive)}>
				<h1>Prácticas</h1>
				<div dangerouslySetInnerHTML={{ __html: this.props.practicas }} />
			</section>,
		];
		return (
			<Fragment>
				<article className={classnames(css.oficina, grid.flexboxgrid)} ref={this.ref}>
					{secciones.map((section, index) => {
						const img = images.find((i) => i.pos == index + 1);
						console.log(img);
						return (
							<Fragment key={index}>
								{img && <Figure image={img} />}
								{section}
							</Fragment>
						);
					})}
				</article>
			</Fragment>
		);
	}
}

export default connect(({ oficina }) => ({ ...oficina }))(Oficina);
