import React from 'react';
import ReactDOM from 'react-dom';

import App from './app';
import data from './common/data';

if (data) {
	ReactDOM.render(<App />, document.getElementById('app'));
} else {
	ReactDOM.render(<p>loading...</p>, document.getElementById('app'));
}
