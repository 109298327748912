import React, { PureComponent } from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import classnames from 'classnames';
import dom from 'dom-helpers';
import memoize from 'memoize-one';

import { connect } from '../../store';

import { pathnameToTitle } from '../../common/helpers';

import OB from '../../common/svg/ob.svg';
import OBlocks from '../../common/svg/blocks.svg';

import Nav from '../Nav';

import css from './header.css';

const CLOSED_HEIGHT = 20;
const OPEN_HEIGHT = 150;
const MAX_HEIGHT_RATIO_HOME = 0.4;
const SPEED = 1.5;

class Header extends PureComponent {
	state = {
		minHeight: 0,
		active: false,
	};
	calculateHeight = memoize(function (windowHeight, windowWidth, scrollTop, isHome) {
		const maxHeight = isHome ? MAX_HEIGHT_RATIO_HOME * windowHeight : 20;
		return Math.max(CLOSED_HEIGHT, maxHeight - scrollTop * SPEED);
	});

	handleOnMouseOver = (e) => {
		const { toggleHeaderHover } = this.props.actions;
		if (this.props.isTouch) {
			toggleHeaderHover(false);
			return;
		}
		if (e.type == 'mouseenter') {
			toggleHeaderHover(true);
		} else if (e.type == 'mouseleave') {
			toggleHeaderHover(false);
		}
	};

	render() {
		const pageTitle = pathnameToTitle(this.props.location.pathname);
		const {
			loaded,
			windowWidth,
			windowHeight,
			scrollTop,
			atPageTop,
			headerActive,
			scrollDistance,
			aboutHeight,
		} = this.props;
		const { minHeight } = this.state;
		const isHome = this.props.location.pathname == '/';
		const height = this.calculateHeight(windowHeight, windowWidth, Math.min(scrollTop, windowHeight), isHome);

		return (
			<div
				className={classnames(css.headerBlock, {
					[css.active]: headerActive,
				})}
				onMouseEnter={this.handleOnMouseOver}
				onMouseLeave={this.handleOnMouseOver}>
				<header className={css.header} style={{}}>
					<Helmet>
						<title>{pageTitle}</title>
						<meta name="description" content={this.props.home.text.replace(/<.+?>/g, '')} />
					</Helmet>
					<h1 className={css.logo}>
						<Link to="/">
							<OB
								className={classnames(css.logo, {
									[css.withLetters]: loaded && headerActive,
								})}
							/>
						</Link>
					</h1>
				</header>
				<Nav className={css.nav} />
			</div>
		);
	}
}
export default withRouter(connect((state) => state)(Header));
