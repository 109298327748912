import React, { Component, Fragment } from 'react';
import classnames from 'classnames';
import { Link, Redirect } from 'react-router-dom';
import dom from 'dom-helpers';
import scroll from 'scroll';

import Hypher from 'hypher';
import es from 'hyphenation.es';
const hypher = new Hypher(es);

import { connect } from '../../store';

import Figure from '../../components/Figure';
import ProjectBlock from '../../components/ProjectBlock';
import Ficha from '../../components/Ficha';
import { Map, Marker } from '../../components/Map';

import css from './project.css';
import grid from '../../common/grid.css';

import { findProjectBySlug } from '../../common/helpers';

const SIZES = ['xxx', 'one', 'two', 'three', 'four', 'five'];
const MEDIUM_SIZES = ['xxx', 'medium-one', 'medium-two', 'medium-three', 'medium-four', 'medium-four'];
const SMALL_SIZES = ['xxx', 'small-one', 'small-two', 'small-three', 'small-three', 'small-three'];

class Project extends Component {
	constructor(props) {
		super(props);
		this.state = { currentImage: null };
	}

	componentDidMount() {
		this.props.actions.findProjectBySlug(this.props.match.params.slug);
	}

	expandFigure = (index, el) => {
		this.setState({ currentImage: index === this.state.currentImage ? false : index });
		setTimeout(() => {
			const top = dom.offset(el).top;
			scroll.top(dom.ownerDocument().documentElement, top - 100, { duration: 250 });
		}, 0);
	};
	render() {
		const { loggedIn, match, currentProject: project } = this.props;

		if (!project) {
			return (
				<article>
					<h1>Página no encontrada</h1>
				</article>
			);
		}

		const memoria = project.memoria ? (
			<section
				className={classnames(css.memoria, grid.cell, grid.two, grid.smallThree)}
				dangerouslySetInnerHTML={{ __html: hypher.hyphenateText(project.memoria) }}
			/>
		) : null;

		return (
			<Fragment>
				<article className={classnames(css.project, grid.cssgrid)}>
					{project.images.map((image, index) => {
						console.log(image);
						return (
							<Fragment key={`fragment-${index}`}>
								{index == 2 && !project.hideficha && <Ficha {...project} />}
								{index == 3 && project.coordenadas && (
									<Map
										className={classnames(grid.cell, grid.two, grid.smallThree)}
										latlng={project.coordenadas}
										zoom={project.zoom || 13}
									/>
								)}
								{index == Math.round(project.images.length / 2) && memoria}
								<Figure
									image={image}
									editable={loggedIn}
									projectSlug={project.slug}
									expandFigure={this.expandFigure}
									index={index}
									expanded={this.state.currentImage === index}
								/>
							</Fragment>
						);
					})}
				</article>
				{project.related.length > 0 && (
					<aside>
						<hr />
						<div className={grid.flexboxgrid}>
							{project.related.map((p, i) => {
								return (
									<ProjectBlock
										key={`related-project-${i}`}
										className={classnames(grid.cell, grid.one)}
										{...findProjectBySlug(p)}
									/>
								);
							})}
						</div>
					</aside>
				)}
			</Fragment>
		);
	}
}

export default connect(({ loggedIn, currentProject }) => ({ loggedIn, currentProject }))(Project);
