import React, { Component } from 'react';
import classnames from 'classnames';

import css from './icon.css';

const Icon = props => {
	const Icon = require(`../../common/svg/${props.name}.svg`);
	return (
		<Icon
			style={props.style}
			className={classnames(props.className, css.icon, {
				[css.block]: props.block,
				[css.outline]: props.outline,
				[css[props.size]]: props.size
			})}
		/>
	);
};

export default Icon;
