import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import classnames from 'classnames';

import Icon from '../Icon';

import { connect } from '../../store';
import { slugify } from '../../common/helpers';

import css from './nav.css';

class Nav extends Component {
	render() {
		const { site, proyectos, currentCategory, className, match, location, windowWidth } = this.props;

		return (
			<nav className={classnames(css.root, className)}>
				<div className={css.categories}>
					<Link
						to="/proyectos"
						className={classnames(css.link, {
							active: /^\/(proyectos\/*)?$/.test(location.pathname)
						})}>
						Todos
					</Link>
					{proyectos.categorias.map((cat, i) => {
						let itemClasses = classnames(css.link, {
							active: location.pathname.split('/').pop() == slugify(cat)
						});
						return (
							<Link key={`category-${i}`} className={itemClasses} to={`/proyectos/${slugify(cat)}`}>
								{cat}
							</Link>
						);
					})}
					{windowWidth > 610 && (
						<Link
							className={css.search}
							to={location.pathname.includes('buscar') ? '/proyectos' : '/proyectos/buscar'}>
							<Icon name="search" className={css.icon} size="large" />
						</Link>
					)}
				</div>
				{windowWidth <= 610 && <div className={css.spacer} />}
				<div className={css.links}>
					<Link
						className={classnames(css.link, {
							active: location.pathname == '/oficina'
						})}
						to="/oficina">
						Oficina
					</Link>
					{site.socialmedia &&
						site.socialmedia.map((sm, index) => (
							<a target="_blank" key={`social-media-${index}`} href={sm.url}>
								{sm.icon ? (
									<Icon
										name={sm.icon}
										size="large"
										className={css.icon}
										style={{ width: '1.25em' }}
									/>
								) : (
									sm.name
								)}
							</a>
						))}
				</div>
			</nav>
		);
	}
}

export default withRouter(
	connect(({ proyectos, site, currentCategory, windowWidth }) => ({ proyectos, site, currentCategory, windowWidth }))(
		Nav
	)
);
