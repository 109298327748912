let data = { proyectos: { proyectos: [] } };
if (typeof document !== 'undefined' && document.querySelector('#site_data')) {
	data = JSON.parse(document.querySelector('#site_data').textContent);
	data.images = [];
	data.proyectos.proyectos.forEach(p => {
		data.images = data.images.concat(p.images);
	});
}

export default data;
export const proyectos = data.proyectos;
export const images = data.images;
export const oficina = data.oficina;
export const home = data.home;
export const site = data.site;
export const loggedIn = data.user && data.user.isCurrent;
if (typeof window !== 'undefined') window.data = data;
