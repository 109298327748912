import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import Imagen from '../Image';

import css from './projectBlock.css';

class ProjectBlock extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { className, canEdit, slug, ratioOverride, thumb, destacado, ano, titulo } = this.props;
		const { caption, ...thumbData } = thumb;
		const captions = [titulo].concat(ano > 0 ? [ano] : []).concat(
			canEdit
				? [
						<a className={css.edit} target="ob-edit" href={`/panel/pages/proyectos/${slug}/edit`}>
							edit
						</a>
				  ]
				: []
		);

		return (
			<article className={classnames(css.projectBlock, className)}>
				<Link className={css.linkBlock} to={`/proyecto/${slug}`} />
				<Imagen {...thumbData} ratioOverride={ratioOverride} />
				<h1 className={css.title}>
					{captions.map((el, index, list) => {
						return (
							<Fragment key={index}>
								{el}
								{index < list.length - 1 ? ' • ' : ''}
							</Fragment>
						);
					})}
				</h1>
			</article>
		);
	}
}

export default ProjectBlock;
