import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { debounce } from '../../common/helpers';

class Track extends Component {
	unlisten = () => {};
	componentDidMount() {
		if (!window.ga) return;
		const debouncedPageview = debounce(function(location) {
			window.ga('set', 'page', location.pathname + location.search);
			window.ga('send', 'pageview', location.pathname + location.search);
		}, 750);
		this.unlisten = this.props.history.listen(debouncedPageview);
	}
	componentWillUnmount() {
		this.unlisten();
	}
	render() {
		return null;
	}
}

export default withRouter(Track);
