import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import dom from 'dom-helpers';
import scroll from 'scroll';

import classnames from 'classnames';
import memoize from 'memoize-one';

import { actions } from '../../store';
import Figure from '../../components/Figure';

import { slugify } from '../../common/helpers';
import data, { proyectos, loggedIn } from '../../common/data';

import css from './tag.css';
import grid from '../../common/grid.css';

class Tag extends Component {
	constructor(props) {
		super(props);
		this.state = { currentImage: null };
	}

	expandFigure = (index, el) => {
		this.setState({ currentImage: index === this.state.currentImage ? false : index });
		setTimeout(() => {
			const top = dom.offset(el).top;
			scroll.top(dom.ownerDocument().documentElement, top - 100, { duration: 250 });
		}, 0);
	};
	filterImagesByTag = memoize((tag) => {
		let images = [];
		proyectos.proyectos.forEach((p) => {
			let moImages = p.images.filter((i) => i.tags.includes(tag));
			moImages = moImages.map((img) => {
				const imgCopy = Object.assign({}, img);
				imgCopy.caption = <Link to={`/proyecto/${p.slug}`}>{p.titulo}</Link>;
				return imgCopy;
			});
			images = images.concat(moImages);
		});
		return images;
	});

	render() {
		let tag = this.props.match.params.tag;
		const images = this.filterImagesByTag(tag);
		return (
			<article className={classnames(css.project)}>
				<h1 className={css.title}>#{tag.toUpperCase()}</h1>
				<section className={grid.cssgrid}>
					{images.map((image, index) => {
						return (
							<Figure
								index={index}
								key={`figure-${index}`}
								image={image}
								expandFigure={this.expandFigure}
								expanded={this.state.currentImage === index}
							/>
						);
					})}
				</section>
			</article>
		);
	}
}

export default Tag;
