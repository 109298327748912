import React, { Component, Fragment } from 'react';
import classnames from 'classnames';

import css from './ficha.css';
import grid from '../../common/grid.css';

export default class Ficha extends Component {
	render() {
		const { titulo, subtitulo, cliente, ano, superficie, ubicacion, estado, equipo } = this.props;
		return (
			<section className={classnames(css.root, grid.cell, grid.three, grid.smallThree)}>
				<h1 className={css.title}>{titulo}</h1>
				{subtitulo && <h2 className={classnames(css.subtitle)}>{subtitulo}</h2>}
				<div className={css.table}>
					{cliente && <Row label="Cliente" value={cliente} />}
					{ano && <Row label="Año" value={ano} tag="time" />}
					{superficie && <Row label="Superficie" value={superficie} />}
					{estado && <Row label="Estado" value={estado} />}
					{ubicacion && <Row label="Ubicación" value={ubicacion} />}
					{equipo && (
						<Row className={css.section} label="Créditos">
							{equipo.split('\n').map((role, idx) => {
								const [label, value] = role.replace(/\*/g, '').split(':');
								return <Row key={`role-${idx}`} label={label} value={value} sub />;
							})}
						</Row>
					)}
				</div>
			</section>
		);
	}
}

const Row = props => {
	if (!props.value && !props.label) return null;

	const Tag = props.tag ? `${props.tag}` : Fragment;
	return (
		<dl className={classnames(css.row, props.className, { [css.sub]: props.sub })}>
			<dt className={css.label}>{props.label}</dt>
			<dd className={css.value}>
				{props.value && <Tag>{props.value}</Tag>}
				{props.children}
			</dd>
		</dl>
	);
};
