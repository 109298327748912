import React, { Component } from 'react';

import { connect } from '../../store';

import css from './background.css';

class Background extends Component {
	render() {
		return <div className={css.background} />;
	}
}

export default connect((state) => state)(Background);
