import React, { Component, Fragment } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

const SIZES = ['xxx', 'one', 'two', 'three', 'four', 'five'];

import Image from '../../components/Image';

import css from './figure.css';
import grid from '../../common/grid.css';

export default class Figure extends Component {
	ref = React.createRef();

	toggleExpanded = () => {
		this.props.expandFigure(this.props.index, this.ref.current);
	};
	render() {
		const { expanded, editable, image, projectSlug = '' } = this.props;
		const { vertical, square, cols, tags, caption, ...imgrest } = image;
		const filename = imgrest.src
			.split('/')
			.pop()
			.replace('.', '․');
		let size = !isNaN(cols) && cols > 0 ? cols : 3;
		return (
			<figure
				ref={this.ref}
				onClick={this.toggleExpanded}
				className={classnames(
					css.figure,
					grid.cell,
					expanded ? grid.five : grid[SIZES[size]],
					expanded ? grid.mediumFive : grid[`medium-${SIZES[Math.max(2, size)]}`],
					expanded ? grid.smallFive : grid[`small-${SIZES[Math.max(3, size)]}`],
					{
						[grid.vertical]: vertical,
						[grid.square]: square
					}
				)}>
				<Image {...imgrest} className={css.image} />
				{(caption || tags || editable) && (
					<figcaption className={css.caption}>
						{caption}
						{caption && tags && ' • '}
						<Tags src={tags} />
						{editable && (
							<a
								className={css.edit}
								target="ob-file-edit"
								href={`/panel/pages/proyectos/${projectSlug}/file/${filename}/edit`}>
								edit
							</a>
						)}
					</figcaption>
				)}
			</figure>
		);
	}
}

const Tags = ({ src }) => {
	return src.split(',').map((tag, index) => {
		if (tag == '') return null;
		return (
			<Fragment key={`tag-${index}`}>
				<Link className={css.tag} to={`/tag/${tag}`}>
					#{tag}
				</Link>{' '}
			</Fragment>
		);
	});
};
