import React, { Component, Fragment } from 'react';
import { withRouter, Link } from 'react-router-dom';
import classnames from 'classnames';

import { connect } from '../../store';

import { slugify } from '../../common/helpers';
import Icon from '../Icon';

import grid from '../../common/grid.css';
import css from './projectlist.css';

class ProjectList extends Component {
	input = React.createRef();
	componentDidMount() {
		this.input.current.focus();
		const { match = {}, searchTerm = '' } = this.props;
		if (!(match.path !== '/proyectos/buscar/:term?' && !match.params.term)) {
			this.props.history.replace(`/proyectos/buscar/${searchTerm}`, {});
		}
	}
	sortBy = sortBy => {
		const asc = sortBy === this.props.sortBy ? !this.props.sortByAsc : false;
		this.props.actions.sortProjectListBy(sortBy, asc);
	};

	onSearchProjects(term) {
		this.props.history.replace(`/proyectos/buscar/${term}`, { term });
		this.props.actions.searchProjectList(term);
	}

	render() {
		const { searchTerm = '', onSearchChange, projectList } = this.props;
		return (
			<section className={css.root}>
				<label className={css.search}>
					<input
						ref={this.input}
						className={css.searchInput}
						value={searchTerm}
						placeholder="Buscar"
						onChange={e => this.onSearchProjects(e.target.value)}
					/>
				</label>
				<div className={css.listRoot}>
					<table className={css.list}>
						<tbody>
							<tr>
								{[
									{ name: 'Proyecto', field: 'titulo' },
									'Año',
									'Cliente',
									{ name: 'Categoría', field: 'categorias' },
									'Superficie',
									'Ubicación',
									'Estado'
								].map((item, index) => {
									const label = 'object' === typeof item ? item.name : item;
									const term = 'object' === typeof item ? item.field : slugify(label);
									return (
										<th
											key={`label-${index}`}
											className={css.title}
											onClick={() => this.sortBy(term)}>
											{label}

											<span
												className={classnames(css.sorting, {
													[css.active]: this.props.sortBy === term
												})}>
												{this.props.sortByAsc ? '▲' : '▼'}
											</span>
										</th>
									);
								})}
							</tr>
							{projectList.map((project, i) => {
								return (
									<tr key={`project-index-item-${i}`}>
										<td>
											<Link to={`/proyecto/${project.slug}`}>{project.titulo}</Link>
										</td>
										<td>{project.ano}</td>
										<td>{project.cliente}</td>
										<td>
											{project.categorias &&
												project.categorias.map((cat, index) => {
													return (
														<Fragment key={`cat-${index}`}>
															<Link to={`/proyectos/${slugify(cat)}`}>{cat}</Link>
															{index < project.categorias.length - 1 && ', '}
														</Fragment>
													);
												})}
										</td>
										<td className={css.right}>
											{project.superficie && project.superficie + ' m²'}
										</td>
										<td>
											{project.direccion ? (
												<a
													target="_blank"
													href={
														'https://maps.google.com/?q=' +
														project.direccion.replace(/\s+/, '+')
													}>
													{project.ubicacion}
												</a>
											) : (
												project.ubicacion
											)}
										</td>
										<td>{project.estado}</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			</section>
		);
	}
}

export default withRouter(
	connect(({ projectList, searchTerm, sortBy, sortByAsc }) => ({
		projectList,
		searchTerm,
		sortBy,
		sortByAsc
	}))(ProjectList)
);
