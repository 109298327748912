import Slugify from 'slugify';
import memoize from 'memoize-one';
import { Search } from 'js-search';

import data from '../common/data';

const projects = data.proyectos.proyectos;

export function slugify(text) {
	return Slugify(text, { lower: true });
}

export const findProjectBySlug = memoize(slug => {
	return projects.find(p => {
		return p.slug == slug;
	});
});

export const findCategoryBySlug = memoize(slug => {
	return data.proyectos.categorias.find(cat => {
		return slugify(cat) == slug;
	});
});

export function pathnameToTitle(pathname) {
	let title;
	const tokens = pathname.split('/');
	const section = tokens[1];
	const param = tokens[2];
	if (section == 'proyecto') {
		const project = findProjectBySlug(param);
		return `${data.site.title}${project ? `, ${project.titulo}` : 'Proyecto no encontrado'}`;
	}
	if (section == 'proyectos') {
		return `${data.site.title}, Proyectos`;
	}
	if (section == 'oficina') {
		return data.site.title;
	}
	return data.site.title;
}

const search = new Search('id');
search.addIndex('titulo');
search.addIndex('subtitulo');
search.addIndex('ano');
search.addIndex('cliente');
search.addIndex('superficie');
search.addIndex('estado');
search.addIndex('ubicacion');
search.addIndex('tags');
search.addIndex('categorias');

search.addDocuments(projects);

export const searchProjects = memoize(term => {
	term = term ? term.trim() : '';
	if (term == '') return projects;
	return search.search(term);
});

export const filterProjects = memoize(cat => {
	if (!cat) return projects;
	return projects.filter(p => {
		if (!Array.isArray(p.categorias)) return false;
		let slugs = p.categorias.map(slugify);
		return slugs.includes(cat);
	});
});

export const sortProjectListBy = memoize((projectList, term, asc) => {
	return projectList.sort(sortBy(term, asc));
});

function sortBy(term, asc) {
	return function(a, b) {
		const aa = Array.isArray(a[term]) ? a[term][0] : a[term];
		const bb = Array.isArray(b[term]) ? b[term][0] : b[term];
		if (aa === bb) return 0;
		if (aa > bb) return asc ? -1 : 1;
		return asc ? 1 : -1;
	};
}

export function debounce(func, wait = 100) {
	let timeout;
	return function(...args) {
		clearTimeout(timeout);
		timeout = setTimeout(() => {
			func.apply(this, args);
		}, wait);
	};
}
