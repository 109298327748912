import React, { Component, Fragment } from 'react';
import classnames from 'classnames';
import { Redirect } from 'react-router-dom';

import { connect } from '../../store';

import ProjectBlock from '../../components/ProjectBlock';

import grid from '../../common/grid.css';
import css from './projectgrid.css';

const SIZES = ['xxx', 'one', 'two', 'three', 'four', 'five'];
const MEDIUM_SIZES = ['xxx', 'medium-two', 'medium-two', 'medium-three', 'medium-four', 'medium-four'];
const SMALL_SIZES = ['xxx', 'small-one', 'small-two', 'small-three', 'small-three', 'small-three'];

class ProjectGrid extends Component {
	render() {
		const { projectGrid, loggedIn } = this.props;
		return (
			<section className={classnames(grid.cssgrid)}>
				{projectGrid.map((project, i) => {
					const { vertical } = project.thumb;

					const cols = project.cols || 1;
					return (
						<Fragment key={`fragment-${i}`}>
							<ProjectBlock
								className={classnames(
									css.project,
									grid.cell,
									grid[SIZES[cols]],
									grid[MEDIUM_SIZES[cols]],
									grid[SMALL_SIZES[cols]],
									{
										[css.featured]: project.destacado,
										[grid.vertical]: vertical
									}
								)}
								canEdit={loggedIn}
								key={`project-${i}`}
								{...project}
							/>
						</Fragment>
					);
				})}
			</section>
		);
	}
}

export default connect(({ projectGrid, loggedIn }) => ({ projectGrid, loggedIn }))(ProjectGrid);
