import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
import classnames from 'classnames';

import { connect } from '../../store';

import css from './profile.css';
import grid from '../../common/grid.css';

class Profile extends Component {
	render() {
		const { persona } = this.props.match.params;
		const profile = this.props.profiles.find((p) => p.slug == persona);
		if (!profile) return <Redirect to="/oficina" />;
		return (
			<Fragment>
				<article className={classnames(css.root, grid.flexboxgrid)}>
					<section id="about" className={classnames(css.section, grid.cell, grid.three)}>
						<h1 className={css.titulo}>{profile.title}</h1>
						<div dangerouslySetInnerHTML={{ __html: profile.text }} />
					</section>
				</article>
			</Fragment>
		);
	}
}

export default connect(({ oficina }) => ({ profiles: oficina.profiles }))(Profile);
